import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';
import styled from 'styled-components';

const ContactStyle = styled.section`
  background-color: ${(props) => (props.backgroundcolor ? '#053d75' : '#fff')};
  padding: 7rem 0 7rem 0;
`;

const Button = styled.a`
  -webkit-text-fill-color: ${(props) => (props.buttoncolor ? '#fff' : '#000')};
  border: ${(props) => (props.border ? '2px solid #fff' : '2px solid #000')};
  color: #000;
  @media (max-width: 750px) {
    width: 50%;
  }
`;

const StyledTitle = styled(Title)`
  color: ${(props) => (props.color ? '#000' : '#fff')};
`;

const Contact = ({ backgroundcolor, color, buttoncolor, border }) => {
  return (
    <ContactStyle backgroundcolor={backgroundcolor}>
      <Container>
        <StyledTitle color={color} title="Schedule A Call With Us Today" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <Button
              buttoncolor={buttoncolor}
              border={border}
              href="/contact"
              className="cta-btn cta-btn--contact"
            >
              Schedule
            </Button>
          </div>
        </Fade>
      </Container>
    </ContactStyle>
  );
};

export default Contact;
