import React, { Component, useState } from 'react';
import Logo from '../Image/Logo';
import { Container } from 'react-bootstrap';
import {
  Div,
  Row,
  MenuLinks,
  MenuIcon,
  Wrapper,
  PageNav,
  StyledLogo,
  MobilePageNav,
  MenuIconContainer,
} from './NavStyles';

export default class NavBar extends Component {
  state = {
    transition: '0s',
    opacity: '1',
    background: '#696969',
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
      this.setState({
        transition: 'background 0.6s ease-out',
        opacity: '0.9',
        background: '#696969',
      });
    } else {
      this.setState({
        transition: '0.7s',
        opacity: '1',
        background: '#696969',
      });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  render() {
    return (
      <Wrapper
        style={{
          transition: this.state.transition,
          opacity: this.state.opacity,
          background: this.state.background,
        }}
      >
        <Container>
          <Div>
            <Row justifycontent="flex-start">
              <StyledLogo href="/">
                <Logo alt="Iffert Media" />
              </StyledLogo>
            </Row>
            <Row justifycontent="flex-end">
              <PageNav href="/">Home</PageNav>
              <PageNav href="/about-iffertmedia">About</PageNav>
              <PageNav href="/services">Services</PageNav>

              <PageNav href="/contact">Contact</PageNav>
              <MobileNavMenu />
            </Row>
          </Div>
        </Container>
      </Wrapper>
    );
  }
}

const MobileNavMenu = () => {
  const [menuOpen, toggleMenuOpen] = useState(false);

  return (
    <div className="mobile-wrapper-nav">
      <MenuIconContainer>
        <MenuIcon menuOpen={menuOpen} onClick={() => toggleMenuOpen(!menuOpen)}>
          <div />
          <div />
          <div />
        </MenuIcon>
      </MenuIconContainer>
      <MenuLinks menuOpen={menuOpen}>
        <ul>
          <li style={{ margin: '0px auto 15px auto' }}>
            <MobilePageNav href="/">Home</MobilePageNav>
          </li>
          <li>
            <MobilePageNav href="/about-iffertmedia">About</MobilePageNav>
          </li>
          <li>
            <MobilePageNav href="/services">Services</MobilePageNav>
          </li>
          <li>
            <MobilePageNav href="/contact">Contact</MobilePageNav>
          </li>
        </ul>
      </MenuLinks>
    </div>
  );
};
