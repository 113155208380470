import React, { useContext } from 'react';
import PortfolioContext from '../../context/context';
import styled from 'styled-components';

const OutLink = styled.a`
  color: #fff;
  background: none;
  cursor: pointer;
`;

const Form = styled.div`
  width: 90%;
  height: 0%;
  background-color: #fff;
  margin-top: -20px;
  padding: 0px !important;
  @media (max-width: 800px) {
    margin-top: 20px;
    height: 0%;
    background-color: #515151;
    width: 60%;
  }
  @media (max-width: 500px) {
    width: 80%;
  }
`;

const Websiteinfo = styled.div`
  text-align: center;
`;

const Footer = ({ data }) => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;

  return (
    <footer className="footer navbar-static-bottom">
      <div>
        <div className="footer-row">
          <div className="info-column">
            <h2>Location</h2>
            <p>Iffert Media: A Nashville Marketing Agency</p>
            <p>Nashville, TN </p>
          </div>
          <div className="info-column" style={{ marginBottom: '30px' }}>
            <h2>Follow us on Social Media!</h2>
            <div className="social-links">
              {networks &&
                networks.map((network) => {
                  const { id, name, url } = network;
                  return (
                    <a key={id} href={url} rel="preconnect" target="_blank" aria-label={name}>
                      <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                    </a>
                  );
                })}
            </div>
          </div>

          <div className="info-column">
            <h2>Contact</h2>
            <div className="text-row" style={{ justifyContent: 'center' }}>
              <i className="fa fa-phone fa-2x" aria-hidden="true" />
              <p style={{ marginLeft: '10px' }}>(315) 725-4354</p>
            </div>
            <div className="text-row" style={{ justifyContent: 'center' }}>
              <i className="fa fa-envelope fa-2x" aria-hidden="true" />
              <p style={{ marginLeft: '10px' }}>cory@iffertmarketing.com</p>
            </div>
          </div>
        </div>
        <hr />
        <Websiteinfo>
          <p className="footer__text">
            © {new Date().getFullYear()} - Iffert Media LLC - All Rights Reserved
          </p>
          <p className="footer__text">
            Website Design by <OutLink href="https://www.cheshtech.com">CheshTech</OutLink>
          </p>
          <a
            href="https://iffertmedia.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="footer__text">Private Policy</p>
          </a>
        </Websiteinfo>
      </div>
    </footer>
  );
};

export default Footer;
