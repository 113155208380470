import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTitle = styled.h1`
  color: ${(props) => (props.color ? '#fff' : '#000')};
  font-size: ${(props) => (props.fontsize ? '5rem' : '3rem')};
  text-align: center;
  @media (max-width: 750px) {
    font-size: ${(props) => (props.fontsize ? '3.5rem' : '3rem')};
  }
  @media (max-width: 500px) {
    font-size: ${(props) => (props.fontsize ? '3rem' : '3rem')};
  }
`;

const BorderBottom = styled.div`
  border-bottom: 2px solid #000;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.display ? 'flex' : 'none')};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${(props) => (props.marginbottom ? '0px' : '30px')};
`;

const Title = ({ title, marginbottom, color, fontsize, display }) => (
  <Fade bottom duration={1000} delay={300} distance="0px">
    <TitleWrapper marginbottom={marginbottom}>
      <StyledTitle fontsize={fontsize} color={color} className="section-title">
        {title}
      </StyledTitle>
      <BorderBottom display={display} />
    </TitleWrapper>
  </Fade>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
