import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export const Nav = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 2px;
  color: white !important;
  margin-right: 30px;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    transition: all 0.3s ease-in-out !important;
    color: #515151; !important;
  }
  ${({ hover }) =>
    hover &&
    `
  color: '#515151;' `}

  &:active {
    border-bottom: 2px solid #ff5f6d !important;
  }
  @media (max-width: 925px) {
    margin-right: 15px;
  }
  @media (max-width: 1100px) {
    display: none;
  }

  .change {
    &:hover {
      transition: all 0.3s ease-in-out !important;
      color: #515151; !important;
    }
  }
`;
export const Row = styled.div`
  display: flex;
  width: ${(props) => props.width};
  justify-content: ${(props) => props.justifycontent};
  align-items: ${(props) => props.alignitems};
  margin-top: ${(props) => props.margintop};
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const PageNav = styled.a`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 2px;
  color: #000;
  margin-left: 30px;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    transition: all 0.3s ease-in-out !important;
    color: #fff;
    // #2c3e50
  }

  //   @media (max-width: 1030px) {
  //     // margin-right: 10px;
  //     font-size: 14px;
  //   }
  //   @media (max-width: 1100px) {
  //     display: none;
  //   }

  .change {
    &:hover {
      transition: all 0.3s ease-in-out !important;
      color: #515151; !important;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Div = styled(Row)`
  max-width: 1600px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1030px) {
    margin: 0 auto;
  }
  @media (max-width: 600px) {
    margin: 0px;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  transform: translateZ(100px);
  // background-image: linear-gradient(to left, #515151, #053d75);
  z-index: 999;
  //   padding: 0 56px;
  align-items: center;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledLogo = styled.a`
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  cursor: pointer;
  z-index: 999;
  //   @media (min-width: 1200px) {
  //     width: 40%;
  //   }

  //   @media (max-width: 992px) {
  //     width: 70%;
  //   }

  //   @media (max-width: 600px) {
  //     width: 100%;
  //   }
  // @media (max-width: 875px) {
  //   display: none;
  // }
`;

// mobile //
export const MobileStyledLogo = styled(Link)`
  display: inline-flex;
  margin-right: auto;
  align-items: center;
  z-index: 999;
  @media (max-width: 600px) {
    margin-right: 0;
    width: 55%;
  }
`;

export const MobileStyledHeader = styled.header`
  display: none;
  @media (max-width: 1100px) {
    height: 3rem;
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.04);
  }
`;

export const MenuIconContainer = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const MenuIcon = styled.button`
  display: none;
  @media (max-width: 767px) {
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 2.5rem;
    outline: thin-dotted;
    z-index: 1111;
    :focus {
      outline: none;
    }
  }
  div {
    width: 2.5rem;
    height: 0.25rem;
    background: ${({ menuOpen }) => (menuOpen ? '#053d75' : 'black')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: opacity 300ms, transform 300ms;
    :first-child {
      transform: ${({ menuOpen }) => (menuOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ menuOpen }) => (menuOpen ? '0' : '1')};
      transform: ${({ menuOpen }) => (menuOpen ? 'translateX(-20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ menuOpen }) => (menuOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const MenuLinks = styled.nav`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    position: absolute;
    z-index: 1005;
    background-color: #053d75;
    top: 100%;
    right: 0;
    height: 100vh;
    width: 100%;
    transition: transform 300ms;
    transform: ${({ menuOpen }) => (menuOpen ? 'translateX(0)' : 'translateY(100%)')};
    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0px;
      padding-left: 0px;

      li {
        list-style: none;
        margin: 15px auto;
      }
    }
  }
`;

export const MobileStyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  transition: color 300ms;
  &:active {
    transition: all 0.3s ease-in-out !important;
    color: #000;
  }
`;

export const MobilePageNav = styled.a`
  text-decoration: none;
  color: white;
  transition: color 300ms;
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
`;
